import logo from './perrito.gif';
import './App.css';


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <strong>
          Hola Ivan, esto se deployo sin código y es gratis
        </strong>
    
      </header>
    </div>
  );
}

export default App;
